import React,{Component, ChangeEvent,useState, useEffect, CSSProperties} from 'react';

import {IAccessToken,IBotPermission,IUserCredentialsToken,ISubscriptionKey,IUserCredentials,ISubscriptionOption, PaypalClient, CreateOrderResponse, Order} from '../Types'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { string } from 'prop-types';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import {CheckCircleOutlineRounded, FormatBold} from '@material-ui/icons';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import moment from 'moment';

import RestHandler from './../RestHandler'

import Modal from '@material-ui/core/Modal';
import QueryStringHelper from '../QueryStringHelper';
import { positions } from '@material-ui/system';

interface IProps {
    onClick: ()=>void
}

const PaypalButton:React.FC<IProps> = (props) => {

    const paypalLogo : CSSProperties = {
        display: "inline-block",
        textShadow: "0px 1px 0px rgba(255, 255, 255, 0.6)",
        fontSize: "20px",
        fontFamily: "Verdana, Tahoma",
        fontWeight: "bold"
    }

    const pay = {
        color: "#253b80"
    }

    const pal = {
        color: "#179bd7"
    }

    const title = {
        fontSize: "14px",
        color: "#505050",
        verticalAlign: "baseline",
        textShadow: "0px 1px 0px rgba(255, 255, 255, 0.6)"
    }
    const button : CSSProperties = {
        padding: "5px 5px",
        border: "1px solid #FF9933",
        borderRadius: "5px",
        backgroundImage: "linear-gradient(#FFF0A8, #F9B421)",
        margin: "0 auto",
        display: "block",
        minWidth: "138px",
        position: "relative"
    }

    return (<div>
        <button style={button} onClick={props.onClick} className="paypal-button">
        <span style={title}>
        Buy now with
        </span>
        <span style={paypalLogo}>
        <i style={pay}>Pay</i><i style={pal}>Pal</i>
        </span>
    </button>
  </div>);
}

export default PaypalButton;