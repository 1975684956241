import React,{Component, ChangeEvent,useState, useEffect, CSSProperties} from 'react';


import RestHandler from './../RestHandler'

import GameVersionSupportComponent from './GameVersionSupportComponent';
import { GameVersion } from '../Types';

interface IProps {
    loggedIn:boolean,
    accessTokenProvider:()=>string
}

const GameVersionSupportContainer:React.FC<IProps> = (props) => {

    const [lastChecked,setLastChecked] = useState<string | null>(null);
    const [supportedGameVersions,setSupportedGameVersions] = useState<string[]>([]);

    useEffect(()=> {

        let didCancel = false;

        async function fetchMyAPI() {

            const response = (await RestHandler.getData("SupportedGameVersionsV2",props.accessTokenProvider()) as string[]);

            if (!didCancel) { // Ignore if we started fetching something else
                setSupportedGameVersions(response);
                setLastChecked(Date());
            }
        }  

        fetchMyAPI();
        return () => { didCancel = true; }; // Remember if we start fetching something else

    },[props.loggedIn])

    return (
    <div style={{flexDirection:"column"}}>
        <div>Supported Game Versions - Last Checked : {lastChecked}</div> 
        {supportedGameVersions.map(a=><div>{a}</div>)}
    </div>)
}

export default GameVersionSupportContainer;