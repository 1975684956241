


class RestHandler {
    public static postData = (path = '', data = {},accessTokenHeader = '',additionalHeaders: Map<string,string> = new Map()) => {
    
    
        var headers: {[k: string]: any} = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessTokenHeader
        };
    
        additionalHeaders.forEach((v,k)=>{
            headers[k] = v;
        });

        // Default options are marked with *
      return fetch((process.env.REACT_APP_API_BASE_URL as string) + path, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit
          headers:headers,
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // no-referrer, *client
          body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(response => response.json()); // parses JSON response into native Javascript objects 
  }

  public static getData = (path = '',accessTokenHeader = '',additionalHeaders: Map<string,string> = new Map()) => {

    var headers: {[k: string]: any} = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessTokenHeader
    };

    additionalHeaders.forEach((v,k)=>{
        headers[k] = v;
    });

    // Default options are marked with *
      return fetch((process.env.REACT_APP_API_BASE_URL as string) + path, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit,
          headers:headers,
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // no-referrer, *client
      })
      .then(response => response.json()); // parses JSON response into native Javascript objects 
  }

  public static  ParseJwt = (token:string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

public static handleResponse = (response:Promise<any>) : Promise<{success:boolean,body:any}> => {
    return response.then((json)=>{
        if(json.Message) {
            console.log("Error : " + json.Message);
            return {success:false,body:json.Message};
        }
        else {
            return {success:true,body:json};
        }
    });
}

}

export default RestHandler;
