import React,{Component, ChangeEvent} from 'react';

import {IAccessToken,IBotPermission,IUserCredentialsToken,ISubscriptionKey,IUserCredentials} from '../Types'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { string } from 'prop-types';

import {CheckCircleOutlineRounded} from '@material-ui/icons';
import { Button } from '@material-ui/core';
import moment from 'moment';


interface IProps {
    subscriptionKeys:ISubscriptionKey[]
}

interface IState {

}

class SubscriptionTable extends Component<IProps,IState>
{
    constructor(props:any) {
        super(props);
    }

    createBotAccessComponent = (botPermissions:IBotPermission[]) => {
        
        let permissionsMap = new Map<string,string[]>();

        botPermissions.forEach((value)=>{
            if(!permissionsMap.has(value.BotType)){
                permissionsMap.set(value.BotType,[]);
            }

                const getResult =  permissionsMap.get(value.BotType);

                if(getResult !== undefined){
                    getResult.push(value.BotPermissionType);
                    permissionsMap.set(value.BotType,getResult);
                }
        });

        let res:{botType:string,botPermissions:string[]}[] = [];
        permissionsMap.forEach(function(val, key) {
            res.push({ botType: key, botPermissions: val });
        });
        
        return (
        <div>
            {res.map(row=>(
                <div key={row.botType}>
                    <div>{row.botType}</div>
                    {/* <ul>
                    {row.botPermissions.map(permission=>(
                        <li key={permission}>{permission}</li>    
                    ))}
                    </ul> */}
                </div>
                
            ))}
        </div>);

    }

    stateText = (revoked:boolean) => {
        return revoked ? "Disabled" : "Enabled";
    }

    inUseText = (inUse:boolean) => {
        return inUse ? "Yes" : "No";
    }

    validToFormatter = (row:ISubscriptionKey) => {
            return row.ValidTo;
    }

    render = () => {
        return (
            <div>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Tier</TableCell>
                            <TableCell align="left">Expires</TableCell>
                            <TableCell align="left">Bot Access</TableCell>
                            <TableCell align="left">In Use</TableCell>
                            <TableCell align="left">Last Used</TableCell>
                            <TableCell align="left">State</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.subscriptionKeys.map(row=>(
                            <TableRow key={row.KeyGuid}>
                                <TableCell component="th" scope="row">
                                    {row.KeyGuid}
                                </TableCell>
                                <TableCell align="left">{row.Name}</TableCell>
                                <TableCell align="left">{row.TierName}</TableCell>
                                <TableCell align="left">{this.validToFormatter(row)}</TableCell>
                                <TableCell align="left">{this.createBotAccessComponent(row.BotPermissions)}</TableCell>
                                <TableCell align="left">{this.inUseText(row.InUse)}</TableCell>
                                <TableCell align="left">{row.LastHeartbeat}</TableCell>
                                <TableCell align="left">{this.stateText(row.Revoked)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            </div>
        );
    }
}

export default SubscriptionTable;