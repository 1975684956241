import React,{Component, ChangeEvent} from 'react';

import QueryStringHelper from './../QueryStringHelper'
import RestHandler from './../RestHandler'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
    
}

interface IState {
    emailConfirmationCode:string,
    emailConfirmationStatus:number,
    emailConfirmationInProgress:boolean
}

class EmailConfirmation extends Component<IProps,IState>
{
    constructor(props:any) {
        super(props);

        var code = QueryStringHelper.getQueryStringParam('emailConfirmationCode');

        this.state = {
            emailConfirmationCode :  code ? code : "",
            emailConfirmationStatus: 0,
            emailConfirmationInProgress:false
        }

    }


    onConfirmEmailClicked = () =>{
        if(this.state.emailConfirmationCode === "")
        {
            this.setState({...this.state,emailConfirmationStatus:2,emailConfirmationInProgress:false});
            return;
        }

        this.setState({emailConfirmationInProgress:true});

        if(this.state.emailConfirmationCode != ''){
          
          RestHandler.handleResponse(RestHandler.postData("verifyEmail",{code:this.state.emailConfirmationCode}))
          .then((json)=>{
            if(json.success)
            {
              this.setState({...this.state,emailConfirmationStatus:1,emailConfirmationInProgress:false});
            }
            else 
            {
              this.setState({...this.state,emailConfirmationStatus:2,emailConfirmationInProgress:false});
            }
            
          })
          .catch(()=>{
            this.setState({...this.state,emailConfirmationStatus:2,emailConfirmationInProgress:false});
          });
        }
      }

    render = () => {
        return (
            <div>
                {(this.state.emailConfirmationStatus === 0) &&
                <div>
                <Button onClick={this.onConfirmEmailClicked} variant="contained" >Click To Verify Email Address</Button>
                {(this.state.emailConfirmationInProgress) && <CircularProgress color="secondary" style={{margin:"10px",alignSelf:"center"}} />}
                </div>
                }
                
                {(this.state.emailConfirmationStatus === 1) &&
                <div>
                <p>Email address verification complete. You should receive an email confirmation if everything worked.</p>
                </div>
                }

                {(this.state.emailConfirmationStatus === 2) &&
                <div>
                <p>Email address verification failed - the emailConfirmationCode was invalid , the email address was no longer valid or the email has already been verified.</p>
                </div>
                }
            </div>
        );
    }
}

export default EmailConfirmation;