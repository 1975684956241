import React,{Component, ChangeEvent} from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { display } from '@material-ui/system';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {AccountCircle,Email,Lock,VpnKey, Link} from '@material-ui/icons';

import InputAdornment from '@material-ui/core/InputAdornment';

import Modal from '@material-ui/core/Modal';
import { CSSProperties } from '@material-ui/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import RestHandler from '../RestHandler'


interface IConfirmModalProps {
    modalTitle:string,
    modalText:string
    onConfirm:()=>void,
    onCancel?:()=>void,
    open:boolean
    
}

interface IConfirmModalState {
}

class ConfirmModal extends Component<IConfirmModalProps,IConfirmModalState>
{
    constructor(props:any) {
        super(props);
    }

    render(){

        const modalStyle:CSSProperties = {
            top:"50%",
            left:"50%",
            transform: "translate(-50%,-50%)",
            position: "absolute" as 'absolute',
            width:"400",
            padding:"10",
            outline:"none",
            backgroundColor:"white",
            borderRadius:"2px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
        };

        const buttonContainer:CSSProperties = {
            justifyContent:"space-around",
            display:"flex"
        }

        return (
            <div>
                {this.props.children}
                <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                >
                <div style={modalStyle}>
                <Typography variant="h6" id="modal-title">
                    {this.props.modalTitle}
                </Typography>
                <Typography variant="subtitle1" id="simple-modal-description">
                    {this.props.modalText}
                </Typography>
                <div style={buttonContainer}>
                <Button onClick={()=> {
                    this.props.onConfirm();
                    }} variant="text">Confirm</Button>
                <Button 
                onClick={()=> {
                    if(this.props.onCancel)
                    {
                        this.props.onCancel();
                    }
                }}
                variant="text">Cancel</Button>
                </div>
                

                </div>
                </Modal>

            </div>

        )
    }
}


interface IAppState {
    username:string,
    password:string,
    signUpCode: string,
          signUpUsername: string
          signUpEmailAddress: string,
          signUpPassword: string,
          signUpConfirmPassword: string,
          signupOrLoginLoading: boolean,
          signUpModalOpen:boolean,
        errorMessage:string  }
  
  interface IProps {
    selectedTab: number,
    signupCode:string,
    handleTabChange: (event:React.ChangeEvent<{}>,newValue: any) => void,
    onSuccessfulLogin: (accessToken:string)=> void
  }

  class LoginSignUpForm extends Component<IProps,IAppState> {

    constructor(props:IProps){
      super(props);

      this.state = {
          username: '',
          password: '',
          signUpCode: props.signupCode,
          signUpUsername: '',
          signUpEmailAddress: '',
          signUpPassword: '',
          signUpConfirmPassword: '',
          signupOrLoginLoading:false,
          signUpModalOpen:false,
          errorMessage:''
      }
    }
    
  
    handleChange = (name:string) => (event:ChangeEvent<HTMLInputElement>) => {
        this.setState({...this.state,[name]:event.target.value});
        
    }
    onConfirm = () => {

        this.setState({...this.state,signupOrLoginLoading:true,signUpModalOpen:false});

        RestHandler.handleResponse(RestHandler.postData('CreateUser',{
            username:this.state.signUpUsername,
            password:this.state.signUpPassword,
            emailAddress:this.state.signUpEmailAddress,
            signUpCode:this.state.signUpCode
        }))
        .then((response)=>{
            if(response.success)
            {
                this.props.onSuccessfulLogin(response.body.accessToken);    
            }
            else 
            {
                this.setState({...this.state,errorMessage:response.body});
            }
            
        }).finally(()=>{
            this.setState({...this.state,signupOrLoginLoading:false})
        })
    }

    onLogin = () => {
      this.setState({...this.state,signupOrLoginLoading:true});

      RestHandler.handleResponse(RestHandler.postData('weblogin',{
            username:this.state.username,
            password:this.state.password
        }))
        .then((response)=>{
            if(response.success)
            {
                this.props.onSuccessfulLogin(response.body.accessToken);    
            }
            else 
            {
                this.setState({...this.state,errorMessage:response.body});
            }
            
        }).finally(()=>{
            this.setState({...this.state,signupOrLoginLoading:false})
        })

    }

    onSuccessfulLogin = (accessToken:string) => {
        this.props.onSuccessfulLogin(accessToken);
    }

    setModalState = (open:boolean) => {
        this.setState({...this.state,signUpModalOpen:open});
    }
  
    render() {

        return (
<div style={{padding:"50px",justifyContent:"center",display:"flex"}}>
            <div style={{maxWidth:"500px"}}>
              <AppBar position="static">
              <Tabs variant="fullWidth" value={this.props.selectedTab} onChange={this.props.handleTabChange}>
                <Tab label="Log In"/>
                <Tab label="Sign Up"/>
              </Tabs>
              </AppBar>
          {this.props.selectedTab === 0 && <div>
            <form style={{display:"flex",flexDirection:"column"}} noValidate autoComplete="off">
            <TextField
              autoFocus={true}
              id="standard-username"
              label="Username"
              value={this.state.username}
              required={true}
              onChange={this.handleChange('username')}
              margin="normal"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                    
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="standard-password"
              label="Password"
              type="password"
              required={true}
              onChange={this.handleChange('password')}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                    
                  </InputAdornment>
                ),
              }}
            />
            <a href="/forgot-password">Forgotten Password?</a>

            <Button onClick={this.onLogin} variant="contained" color="primary">Log In</Button>
            {this.state.signupOrLoginLoading && <CircularProgress color="secondary" style={{margin:"10px",alignSelf:"center"}} />}
            {this.state.errorMessage !== '' && <div style={{margin:"10px",alignSelf:"center",color:"red"}}>Error logging in : {this.state.errorMessage}</div>}
          </form>
          </div>}
          {this.props.selectedTab === 1 && <div>
            <form style={{display:"flex",flexDirection:"column"}} noValidate={false} autoComplete="off">
            <Typography style={{paddingTop:"5px"}}>
                Flexbot is an invite only private bot, you must have a sign-up code to create an account.
            </Typography>
            <TextField
              id="standard-signup-token"
              label="Signup-Code"
              required={true}
              onChange={this.handleChange('signUpCode')}
              margin="normal"
              value={this.state.signUpCode}
              placeholder="e.g. 69748248236-239423649264-2637426423"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                    
                  </InputAdornment>
                ),
              }}
              
            />
            <TextField
              autoFocus={true}
              id="standard-signUp-username"
              label="Username"
              required={true}
              onChange={this.handleChange('signUpUsername')}
              margin="normal"
              value={this.state.signUpUsername}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                    
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="standard-signUp-email"
              label="Email Address"
              type="email"
              required={true}
              onChange={this.handleChange('signUpEmailAddress')}
              margin="normal"
              value={this.state.signUpEmailAddress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                    
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="standard-signUp-password"
              label="Password"
              type="password"
              required={true}
              onChange={this.handleChange('signUpPassword')}
              margin="normal"
              value={this.state.signUpPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                    
                  </InputAdornment>
                ),
              }}
            />
            <ConfirmModal 
            modalText={"This sign-up code can only be used once. Are you sure you want to create this account with these details?"} 
            modalTitle={"Confirm"} 
            onConfirm={this.onConfirm} 
            onCancel={()=> this.setModalState(false)}
            open={this.state.signUpModalOpen}>
            
            </ConfirmModal>
            <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignContent:"center"}}>
            <Button 
            disabled={this.state.signupOrLoginLoading}
            variant="contained" 
            onClick={()=> this.setModalState(true)}>Sign Up</Button>
            {this.state.signupOrLoginLoading && <CircularProgress color="secondary" style={{margin:"10px",alignSelf:"center"}} />}
            {this.state.errorMessage !== '' && <div style={{margin:"10px",alignSelf:"center",color:"red"}}>Error signing up : {this.state.errorMessage}</div>}
            </div>
          </form>
          </div>}
          
            </div>
          
          </div>)
    }
}

export default LoginSignUpForm