import React,{Component, ChangeEvent} from 'react';
import RestHandler from '../RestHandler';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { Email } from '@material-ui/icons';

interface IProps {
}

interface IState {
    emailAddress:string,
    state:number;
}

class PasswordReset extends Component<IProps,IState>
{
    constructor(props:IProps) {
        super(props);

        this.state = {emailAddress:"",state:0}
    }

    onPasswordResetClick = async () => {
        
        this.setState({...this.state,state:1});

        const result = await RestHandler.postData("forgotPassword",{"emailAddress":this.state.emailAddress});

        this.setState({...this.state,state:2});


    }

    handleChange = (name:string) => (event:ChangeEvent<HTMLInputElement>) => {
        this.setState({...this.state,[name]:event.target.value});
        
    }

    render = () => {

        return (
            <div style={{display:"flex",justifyContent:"center"}}>
                <div style={{display:"flex",flexDirection:"column",maxWidth:300,justifyContent:"center"}}>
                {this.state.state == 0 &&
                <>
                <TextField
                id="emailAddress"
                label="Email Address"
                type="email"
                required={true}
                onChange={this.handleChange('emailAddress')}
                margin="normal"
                value={this.state.emailAddress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}/>
                  <Button 
                  
              variant="contained" 
              onClick={()=> this.onPasswordResetClick()}>Send Password Reset</Button>
                </>
                }
                {this.state.state == 1 &&
                <>
                Loading...
                </>
                }
                {this.state.state == 2 &&
                <>
                Check your email for a password reset confirmation.
                </>
                }
            </div>
                
            

                
                
            </div>
        );
    }
}

export default PasswordReset;