import React, { Component, ChangeEvent } from 'react';
import videos from './Videos';

interface IProps {
    loggedIn: boolean
}

interface IState {

}

export class Guides extends Component<IProps, IState>
{
    constructor(props: IProps) {
        super(props);
    }
    render = () => {

        

        

        return (
            <div>
                {this.props.loggedIn &&
                    <div>
                        <h1>Guides</h1>
                        <a href="https://github.com/mousewookiesoftware/BotDocumentation">Bot Documentation - Covers basic combat routine and profile creation with examples.</a>
                        <p>if you get 'DLL missing' error when clicking 'Attach' you need to install c++ redist. use this direct microsoft mirror link : <a href="https://aka.ms/vs/16/release/vc_redist.x64.exe">https://aka.ms/vs/16/release/vc_redist.x64.exe</a>
                        <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/id4VQmR.png"></img>
                                </div>
                        </p>


                        <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/LSfWGv0.png"></img>
                                </div>
                        <div>
                            <details>
                                <summary>General Video Guides</summary>

                                
                            <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.better_flying_gather_nav)} />
                            <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_blackspots_guide)} />
                            <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_special_return_to_town)} />
                            </details>
                        </div>
                        <div>
                            <details>
                                <summary >Discord Notification Setup</summary>
                            <p id="discord-notification-setup">
                            <b>I strongly advise creating a brand new discord server for this rather than using an existing server because the discord bot will have FULL ADMIN RIGHTS on the server.
I take no responsibility for any accidental deletion of messages or corruption of private discord server used. The bot will have read and write access to all messages in the private server.</b>

                            
                            </p>
                            <ol>
                                <li>create a private discord server</li>
                                <li>follow - <a href="https://discordnet.dev/guides/getting_started/first-bot.html#creating-a-discord-bot">Discord bot setup guide</a> to create a discord bot and set it to "PRIVATE"</li>
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/A88Mznm.png"></img>
                                </div>
                                <li>follow - <a href="https://discordnet.dev/guides/getting_started/first-bot.html#adding-your-bot-to-a-server">Adding your bot to a server</a> and
add your bot to the server - make sure to use following settings when generating url :
Scopes: "bot" - Bot Permissions : "Administrator"
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px" }} src="https://imgur.com/nbcFXSO.png"></img>
                                </div>

                                </li>
                                <li>open 0auth grant url using browser - pick the private server you created in step 1
</li>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px", maxHeight:"400px" }} src="https://imgur.com/dEdaCHW.png"></img>
</div>
<li>allow bot access to discord server : 
</li>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
                                        <img style={{ maxWidth: "800px", maxHeight:"400px" }} src="https://imgur.com/6zl4cku.png"></img>
</div>
<li>should now see your bot show up in server if you have done everything correct : 
</li>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/plRsx5T.png"></img>
</div>
<li>copy channel ID from private discord server and add it into bot settings : 
</li>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/uMoJVtL.png"></img>
</div>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/fe4bL5B.png"></img>
</div>
<li>generate token for bot - KEEP THIS SECRET! and copy token into bot config : 
</li>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/cXvvyh6.png"></img>
</div>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/UMwhFol.png"></img>
</div>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/fe4bL5B.png"></img>
</div>
<li>
finally set "DiscordCustomBotIdentifer" option to something to identify your bot from others you might be running.
To test everything is working - enable "SendDiscordNotifications" setting in bot config and start the bot.
You should see some information about "connecting to discord..."
</li>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/g5Rm9Yr.png"></img>
</div>
<li>Last step is to customise what events should trigger discord notifcations</li>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/7R2MkXk.png"></img>
</div>
</ol>
<h4>Troubleshooting</h4>
<p>if you see the bot constantly showing Connecting + Disconnecting
or message like "<code>Discord send message failed. attempt number 2. Exception Detail 
: The server responded with error 401: 401: Unauthorized</code>" usually means that the "DiscordBotToken" and/or "DiscordChannelId" settings are incorrect and you need to go through steps 4 - 6 again to ensure the discord bot has access to the channel specified by DiscordChannelId and the DiscordBotToken is valid.</p>
<div style={{ display: "flex", flexWrap: "wrap", padding: "0 4px" }}>
<img style={{ maxWidth: "800px", maxHeight:"400px" }} 
src="https://imgur.com/NKKEAkq.png"></img>
</div>            
                            </details>
                        </div>
                        <div>
                            <details>
                                    <summary>Monitor / Relogger Video Guides</summary>
                                    <div>
                                        <p>
                                        In order to run the monitor application you must have the <a href='https://dotnet.microsoft.com/en-us/download/dotnet/thank-you/runtime-desktop-6.0.8-windows-x64-installer' target="_blank">.net 6 desktop runtime</a> installed 
                                        </p>
                                    </div>
                                    <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_monitor_relogger_1)} />
                                    <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_monitor_relogger_2)} />
                                    <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_monitor_relogger_3)} />
                                    <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_monitor_relogger_4)} />
                            </details>
                        </div>
                        <div>
                            <details>
                                <summary>Fishing Bot Video Guides</summary>
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_fishing_bot_1_video)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_fishing_bot_vendor_mount_video)} />
                            </details>
                        </div>
                        <div>
                            <details>
                                <summary>Stationary Grind Bot Guides</summary>
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.stationary_grind_simple_profile)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.stationary_grind_pull_profile)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.stationary_grind_settings_overview)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.stationary_grind_advanced_looting_skinning_settings_overview)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.stationary_grind_advanced_combat_overview)} />
                                <p>Combat routine used in video <a href="https://github.com/mousewookiesoftware/BotDocumentation/blob/master/Examples/CombatRoutines/Stationary%20Grind%20Bot/Dragonflight/Druid/druid-stationary-moonkin-demo.json">druid-stationary-moonkin-demo.json</a></p>
                            </details>
                        
                            
                        </div>
                        <div>
                            <details>
                                <summary>Grind Bot Video Guides</summary>
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_grind_bot_profile_creation_video)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_grind_bot_basic_settings_video)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_multi_pull)} />
                            </details>
                        
                            
                        </div>
                        <div>
                            <details>
                                <summary>Gather Bot Video Guides</summary>
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_gather_blackspots_1_video)} />
                            </details>
                        </div>
                        <div>
                            <details>
                                <summary>Auction House Bot Video Guides</summary>
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.auction_house_bot_demo)} />
                                {/* <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_1)} /> */}
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_2)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_3)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.guide_auction_house_bot_4)} />
                            </details>
                        </div>
                        <div>
                            <details>
                                <summary>Chain Profile Video Guides</summary>
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_guide_1)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_guide_2)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_guide_3)} />
                                <div dangerouslySetInnerHTML={videos.createVideoHtml(videos.chain_profile_timelapse)} />
                            </details>
                        </div>
                    </div>}
                {!this.props.loggedIn &&
                    <p>Log in to see guides. <a href="/">Log in</a></p>
                }



            </div>
        );
    }
}