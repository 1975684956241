import React,{Component, ChangeEvent,useState, useEffect, CSSProperties} from 'react';

import {IAccessToken,IBotPermission,IUserCredentialsToken,ISubscriptionKey,IUserCredentials,ISubscriptionOption, PaypalClient, CreateOrderResponse, IProductDealDetails} from '../Types'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { string } from 'prop-types';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import {CheckCircleOutlineRounded} from '@material-ui/icons';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import moment from 'moment';

import RestHandler from './../RestHandler'

import Modal from '@material-ui/core/Modal';
import PaypalButton from './PaypalButton';

interface IProps {
    open:boolean,
    loggedIn:boolean,
    accessTokenProvider:()=>string,
    onCancel: ()=>void;
}

const BuySubscriptionModal:React.FC<IProps> = (props) => {
    
        const [productPaymentPending,setProductPaymentPending] = useState("");
        const [availableSubscriptionOptions,setAvailableSubscriptionOptions] = useState<ISubscriptionOption[]>([]);
        const [availableDeals,setAvailableDeals] = useState<IProductDealDetails[]>([]);

        useEffect(()=> {

            let didCancel = false;
    
            async function fetchMyAPI() {

                const productsResponse = (await RestHandler.getData("v2/products",props.accessTokenProvider()) as ISubscriptionOption[]);
                const dealsResponse = (await RestHandler.getData("v2/deals",props.accessTokenProvider()) as IProductDealDetails[]);

                if (!didCancel) { // Ignore if we started fetching something else
                    setAvailableSubscriptionOptions(productsResponse);
                    setAvailableDeals(dealsResponse);
                }
            }  
    
            fetchMyAPI();
            return () => { didCancel = true; }; // Remember if we start fetching something else
    
        },[props.loggedIn])


        const modalStyle:CSSProperties = {
            top:"50%",
            left:"50%",
            transform: "translate(-50%,-50%)",
            position: "absolute" as 'absolute',
            width:"400",
            padding:"10",
            outline:"none",
            backgroundColor:"white",
            borderRadius:"2px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
        };

        const onProductBuyButtonClick = async (product:ISubscriptionOption) => {

            setProductPaymentPending("product_"+product.id);

            var createOrderResponse = (await RestHandler.postData("v2/order",{productId:product.id},props.accessTokenProvider()) as CreateOrderResponse);

            window.location.replace(createOrderResponse.approveUrl);
            
        }

        const onDealBuyButtonClick = async (deal:IProductDealDetails) => {

            setProductPaymentPending("deal_"+deal.deal.productDealId);

            var createOrderResponse = (await RestHandler.postData("v2/orderDeal",{productDealId:deal.deal.productDealId},props.accessTokenProvider()) as CreateOrderResponse);

            window.location.replace(createOrderResponse.approveUrl);
            
        }

        return (<div>
            {props.open && 
            <div>
                <Typography variant="h6" style={{margin:"5px"}}>
                Single Subscription Keys
                    </Typography>
                <Typography variant="subtitle1" >
                
                    
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Subscription Key Name</TableCell>
                            <TableCell align="left">Price</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {availableSubscriptionOptions.map((row:ISubscriptionOption)=>(
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">${row.price}</TableCell>
                                <TableCell align="left">
                                    {productPaymentPending == "" && <PaypalButton onClick={()=>onProductBuyButtonClick(row)}></PaypalButton>}
                                    {(productPaymentPending != "" && productPaymentPending == "product_"+row.id) && <CircularProgress color="secondary" style={{margin:"10px",alignSelf:"center"}} />}
                                    {(productPaymentPending != "" && productPaymentPending != "product_"+row.id) && <Button disabled={true}>Buy</Button>}
                                </TableCell>
                            </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </Typography>

                <Typography variant="h6" style={{margin:"5px"}}>
                    Bulk Discounted Subscription Keys
                </Typography>
                <Typography variant="subtitle1">
                    
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Subscription Key Name</TableCell>
                            <TableCell align="left">Number of keys</TableCell>
                            <TableCell align="left">Discount</TableCell>
                            <TableCell align="left">Total Price</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {availableDeals.sort((a,b)=>a.deal.order - b.deal.order).map((row:IProductDealDetails)=>(
                            <TableRow key={row.deal.productDealId}>
                                <TableCell component="th">
                                    {row.product.name}
                                </TableCell>
                                <TableCell component="th">
                                    {row.deal.quantity}
                                </TableCell>
                                <TableCell component="th">
                                    {row.deal.discountPercentage}%
                                </TableCell>
                                <TableCell component="th">
                                    ${(row.deal.quantity * row.product.price) - ((row.deal.quantity * row.product.price) * row.deal.discountPercentage / 100) }
                                </TableCell>
                                <TableCell align="left">
                                    {productPaymentPending == "" && <PaypalButton onClick={()=>onDealBuyButtonClick(row)}></PaypalButton>}
                                    {(productPaymentPending != "" && productPaymentPending == "deal_"+row.deal.productDealId) && <CircularProgress color="secondary" style={{margin:"10px",alignSelf:"center"}} />}
                                    {(productPaymentPending != "" && productPaymentPending != "deal_"+row.deal.productDealId) && <Button disabled={true}>Buy</Button>}
                                </TableCell>
                            </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </Typography>
                </div>
                }
                </div>);
    }

export default BuySubscriptionModal;