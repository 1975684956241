import React,{Component, ChangeEvent} from 'react';
import RestHandler from '../RestHandler';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { Email, Lock } from '@material-ui/icons';
import QueryStringHelper from '../QueryStringHelper';

interface IProps {
}

interface IState {
    passwordConfirm:string,
    password:string,
    state:number,
    nonMatchingPassword:boolean
}

class ResetPassword extends Component<IProps,IState>
{
    constructor(props:IProps) {
        super(props);

        this.state = {passwordConfirm:"",password:"",state:0,nonMatchingPassword:false}
    }

    onPasswordResetClick = async () => {
        
        if(this.state.password !== this.state.passwordConfirm){
            this.setState({...this.state,nonMatchingPassword:true});
            return;
        }
        else{
            this.setState({...this.state,nonMatchingPassword:false});
        }

        this.setState({...this.state,state:1});

        const code = QueryStringHelper.getQueryStringParam('code');

        const result = await RestHandler.postData("resetPassword",{"password":this.state.password,"code":code});

        if(result.success)
        {
            this.setState({...this.state,state:2});
        }
        else {
            this.setState({...this.state,state:3});
        }
    }

    handleChange = (name:string) => (event:ChangeEvent<HTMLInputElement>) => {
        this.setState({...this.state,[name]:event.target.value});
        
    }

    render = () => {

        return (
            <div style={{display:"flex",justifyContent:"center"}}>
                <div style={{display:"flex",flexDirection:"column",maxWidth:300,justifyContent:"center"}}>
                {this.state.state === 0 && 
                    
                    
                        <>
                            <TextField
                            id="password"
                            label="Password"
                            type="password"
                            required={true}
                            onChange={this.handleChange('password')}
                            margin="normal"
                            value={this.state.password}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <Lock />
                                </InputAdornment>
                            ),
                            }}/>
                            <TextField
                            id="passwordConfirm"
                            label="Confirm Password"
                            type="password"
                            required={true}
                            onChange={this.handleChange('passwordConfirm')}
                            margin="normal"
                            value={this.state.passwordConfirm}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <Lock />
                                </InputAdornment>
                            ),
                            }}/>
                            <Button 
                            
                            variant="contained" 
                            onClick={()=> this.onPasswordResetClick()}>Reset Password</Button>
                                {this.state.nonMatchingPassword && <div>Passwords don't match!</div>}
                        </>
                }
                {this.state.state === 1 &&
                <>
                Loading...
                </>
                }

                {this.state.state === 2 &&
                <>
                Your password has been reset! <a href="/">Log in again</a>
                </>
                }
                {this.state.state === 3 &&
                <>
                Failed to reset password.
                </>
                }
                </div>
                </div>
        );
    }
}

export default ResetPassword;