
export default {
    guide_gather_blackspots_1_video:"https://www.youtube.com/embed/LtChlj5h7Cs",
    guide_fishing_bot_1_video: "https://www.youtube.com/embed/27r_NXG38sw",
    guide_fishing_bot_vendor_mount_video: "https://www.youtube.com/embed/QUV3q-qR1Vw",
    guide_grind_bot_profile_creation_video: "https://www.youtube.com/embed/T-KTEqjJoSE",
    guide_grind_bot_basic_settings_video: "https://www.youtube.com/embed/3J3hjhQ0AQ4",
    guide_debug_waypoints_video: "https://www.youtube.com/embed/ytALWMvsvqU",
    guide_blackspots_guide: "https://www.youtube.com/embed/AEXyr46qiz8",
    auction_house_bot_demo: "https://www.youtube.com/embed/lWdcnagqbCU",
    guide_auction_house_bot_1: "https://www.youtube.com/embed/rFvbK6EyX2c",
    guide_auction_house_bot_2: "https://www.youtube.com/embed/pVzeQ_uTERE",
    guide_auction_house_bot_3: "https://www.youtube.com/embed/vOR0I7ryI0g",
    guide_auction_house_bot_4:"https://www.youtube.com/embed/2dj02LANzZ0",
    demo_smooth_ctm_movement:"https://www.youtube.com/embed/hCC8IcIg5zA",
    demo_higher_mob_level_blackspot_demo:"https://www.youtube.com/embed/lebyGvT--KI",
    guide_monitor_relogger_1:"https://www.youtube.com/embed/tpjFTHXATa8",
    guide_monitor_relogger_2:"https://www.youtube.com/embed/eIrZvKsOXhY",
    guide_monitor_relogger_3:"https://www.youtube.com/embed/q94vmWmSlx8",
    guide_monitor_relogger_4:"https://www.youtube.com/embed/1DAL8JeYpkA",
    demo_monitor:"https://www.youtube.com/embed/xDUgxB0-yqc",
    guide_special_return_to_town:"https://www.youtube.com/embed/YoCeFzJ00uo",
    better_flying_gather_nav:"https://www.youtube.com/embed/hTyBWnNJi5A",
    guide_multi_pull: "https://www.youtube.com/embed/Am-aX6E7GWA",
    stationary_grind_demo:"https://www.youtube.com/embed/LEea7DH0GNE",
    stationary_grind_simple_profile: "https://www.youtube.com/embed/S9rMSLwctDg",
    stationary_grind_pull_profile: "https://www.youtube.com/embed/uGSfVzQ-bi8",
    stationary_grind_settings_overview: "https://www.youtube.com/embed/59KkXuQi6ko",
    stationary_grind_advanced_looting_skinning_settings_overview: "https://www.youtube.com/embed/inkfXYcbT2U",
    stationary_grind_advanced_combat_overview: "https://www.youtube.com/embed/hiEImkoA8Pw",
    chain_profile_guide_1: "https://www.youtube.com/embed/cEuP5zvtgMM?si=24ylfzvB0ju0ZplH",
    chain_profile_guide_2: "https://www.youtube.com/embed/dpWvg3KUmf4?si=Zn0blhCFBkgwia91",
    chain_profile_guide_3: "https://www.youtube.com/embed/TG_nsTV0Diw?si=XzmGqjvF6Op8snwB",
    chain_profile_timelapse: "https://www.youtube.com/embed/Q-E7yPKJcSM?si=AupIzNHSOJEkKOCj",
    createVideoHtml(embedVideoUrl)  {
        return {
            __html: `<iframe width="560" height="315" src="${embedVideoUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        }
    }
}