import React,{Component, ChangeEvent, CSSProperties} from 'react';
import './App.css';
import LoginSignUpForm from './components/LoginSignUpForm'
import SubscriptionTable from './components/SubscriptionTable'

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import {AccountCircle,Email,Lock, TheatersRounded,AutorenewRounded, LineStyle} from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';

import debounce from 'lodash.debounce';

import {IAccessToken,IBotPermission,IUserCredentialsToken,ISubscriptionKey,IUserCredentials, PaypalClient} from './Types'
import RestHandler from './RestHandler'
import QueryStringHelper from './QueryStringHelper'
import { number } from 'prop-types';
import PatchNotes from './components/PatchNotes'
import EmailConfirmation from './components/EmailConfirmation'
import PaypalExpressBtn from 'react-paypal-express-checkout';



import {BrowserRouter as Router,Switch,Route,Link} from 'react-router-dom'
import BuySubscriptionModal from './components/BuySubscriptionKeyModal';
import ConfirmCheckout from './components/ConfirmCheckout';
import LoggedOut from './components/LoggedOut';
import { display, flexbox } from '@material-ui/system';
import PasswordReset from './components/PasswordReset';
import ResetPassword from './components/ResetPassword';
import GameVersionSupportContainer from './components/GameVersionSupportContainer';
import { Guides } from './components/Guides';
import { access } from 'fs';

interface IAppState {
  selectedTab: number,
  initialCode:string,
  loggedIn:boolean,
  accessToken:IAccessToken | null,
  accessTokenJwt:string | null,
  
  downloadBotUrl:string,
  downloadVersionNumber:string,
  subscriptionModalOpen:boolean,
  subscriptionKeys:ISubscriptionKey[]

}

interface IProps {}

class App extends Component<IProps,IAppState> {

  private debouncedRefresh:any;

  constructor(props:IProps){
    super(props);

    this.state = {
      initialCode:'',
      selectedTab: 0,
      subscriptionKeys: [],
      loggedIn:false,
      accessToken:null,
      accessTokenJwt:null,
      downloadBotUrl:'',
      downloadVersionNumber:'',
      subscriptionModalOpen:false

    };

    this.debouncedRefresh = debounce(() => {
      if(this.state.accessTokenJwt)
      {
        this.updateSubscriptionKeys(this.state.accessTokenJwt)
      }
    },1000);
  }
  

  handleChange = (name:string) => (event:ChangeEvent<HTMLInputElement>) => {
      
  }

  handleTabChange = (event:React.ChangeEvent<{}>,newValue: any) => {
    this.setState({
      selectedTab: newValue
    })
  }

  updateSubscriptionKeys = (accessTokenJwt:string) => {
    RestHandler.postData("subscriptionKeys",{}, accessTokenJwt)
    .then((json)=>{
      this.setState({...this.state,subscriptionKeys:json});
    });
  }

  loggedIn = (accessTokenJwt:string) => {

    localStorage.setItem('access_token',accessTokenJwt);

    this.setState({...this.state,loggedIn:true,accessTokenJwt:accessTokenJwt,accessToken:(RestHandler.ParseJwt(accessTokenJwt) as IAccessToken)});

    this.getAccessToken();

    this.updateSubscriptionKeys(accessTokenJwt);

    RestHandler.getData("v2/latest",accessTokenJwt)
    .then((json)=>{
      this.setState({...this.state,downloadBotUrl:json.downloadUrl,downloadVersionNumber:json.version.versionNumber});
    });

  }

  isExpired = (exp:number,precheck:number=0) => {
    if (Date.now() + precheck >= exp * 1000) {
      return true;
    }
  }

  getAccessToken = ():string => {

      const accessToken = localStorage.getItem('access_token');

      //if token is just not set somehow... 
      if(accessToken === null)
      {
        localStorage.removeItem('access_token');
        window.location.replace("/logged-out");
        
        return "";
      }

      const parsedAccessToken = (RestHandler.ParseJwt(accessToken) as IAccessToken);

      
        

      const accessTokenParsedExp = parsedAccessToken.exp;

      //if its an old token without new properties logout
      if(!parsedAccessToken.access_token.hasOwnProperty("Username")){
        localStorage.removeItem('access_token');
        window.location.replace("/logged-out");
      }

      const minuteInMs = 60000;

      const hourInMs = minuteInMs * 60;

      //access token expiry is 6 hours
      //if our token is going to expire in the next hour log the user out and get a new token

      //if token has expired
      if(this.isExpired(accessTokenParsedExp,hourInMs*1)){
        localStorage.removeItem('access_token');
        window.location.replace("/logged-out");
        
        return "";
      }

      return accessToken;
  }

  componentWillMount() {
    
    const code = QueryStringHelper.getQueryStringParam('signup-code');
    const selectedTab = QueryStringHelper.getQueryStringParam('selectedTab');


    let initialState = {
      initialCode:'',
      selectedTab:0
    };

    if(selectedTab)
    {
      initialState.selectedTab = parseInt(selectedTab);
    }

    if(code){
      initialState.initialCode = code;
    }

    const accessToken = localStorage.getItem('access_token') || '';

    if(accessToken !== '')
    {
      this.loggedIn(accessToken);
    }
    this.setState(initialState);
  }

  componentDidMount(){
    
  }

  onLogoutClicked = () => {
    localStorage.removeItem("access_token");
    window.location.replace("/");
  }

  render() {

    const buyButtonStyle : CSSProperties = {
      padding: "5px 5px",
      border: "1px solid #FF9933",
      borderRadius: "5px",
      maxWidth: "300px",
      backgroundImage: "linear-gradient(#FFF0A8, #F9B421)"
  }

    return (
      <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Typography component="div" style={{height: '100vh' }} >
          
          <Router>
          <AppBar position="static">
            <Toolbar>
              
              <Typography variant="h6" style={{"flexGrow":1}} >
                <Link style={{color:"white"}} to="/"><a>Flexbot</a></Link>
              </Typography>
              <div style={{display:"flex",justifyContent:"space-between"}}>

              
              {this.state.loggedIn && <Link style={{color:"white",padding:"10px"}} to="/patch-notes">Patch Notes</Link>}
              {this.state.loggedIn && <Link style={{color:"white",padding:"10px"}} to="/guides">Guides</Link>}
              {this.state.loggedIn && <a style={{color:"white",padding:"10px"}} href="https://trello.com/b/8jo9c4P5/issue-tracker">Issue Tracker</a>}
              </div>
              {this.state.loggedIn && this.state.accessToken &&
                
                <div style={{display:"flex",flexDirection:"column"}}>
                  <Button onClick={this.onLogoutClicked} color="inherit" >Log Out</Button>
                  <div>
                    {(this.state.accessToken !== null) && <div>Welcome {this.state.accessToken.access_token.Username}</div>}
                  </div>
                </div>
              
              }
              
              
            </Toolbar>
          </AppBar>
          <Switch>
            
           <Route path="/" exact>
            <div>
            {(this.state.loggedIn && this.state.accessToken) && 
              <div>
                {(this.state.downloadBotUrl != '') && <div><p><a href={this.state.downloadBotUrl}>Download Latest Bot Version {this.state.downloadVersionNumber}</a> This link will only be valid for 5 minutes. Refresh the page to get a new valid link.</p></div>}
                <GameVersionSupportContainer accessTokenProvider={this.getAccessToken} loggedIn={this.state.loggedIn}/>
                <div style={{display:"flex"}}>
                <Typography variant="h6" style={{"flexGrow":1}} >
                  Subscriptions
                </Typography>
                <IconButton onClick={()=> this.debouncedRefresh()}>
                <AutorenewRounded />
                </IconButton>
                </div>
                {this.state.accessToken.access_token.Trusted === true && 
                <div style={{display:"flex",paddingTop:"5px",flexDirection:"column"}}>
                  
                  <Button style={buyButtonStyle} variant="contained" onClick={()=>this.setState({subscriptionModalOpen:true})}>
                  Buy New Subscription Key
                  </Button>
                  
                    <BuySubscriptionModal 
                    accessTokenProvider={this.getAccessToken}
                    onCancel={()=>{this.setState({subscriptionModalOpen:false})}} 
                    open={this.state.subscriptionModalOpen}
                    loggedIn={this.state.loggedIn}>
                    </BuySubscriptionModal>
                  
                  
                  
                  
                </div>
                }
              <SubscriptionTable 
              subscriptionKeys={this.state.subscriptionKeys}>
              </SubscriptionTable>
              
              </div>
            }
            {!this.state.loggedIn && 
              <LoginSignUpForm 
              signupCode={this.state.initialCode} 
              selectedTab={this.state.selectedTab} 
              handleTabChange={this.handleTabChange}
              onSuccessfulLogin={this.loggedIn}>
              </LoginSignUpForm>
            }
            </div>
           </Route> 
           <Route path="/patch-notes" render={(routeProps)=>(
             <PatchNotes loggedIn={this.state.loggedIn}></PatchNotes>
           )}/>
           <Route path="/guides" render={(routeProps)=>(
             <Guides loggedIn={this.state.loggedIn}></Guides>
           )}/>
           <Route path="/verify-email" component={EmailConfirmation} />
           <Route path="/logged-out" component={LoggedOut} />
            
           <Route path="/confirm-checkout" render={(routeProps) => (
                <div>
                  {this.state.loggedIn && this.state.accessToken && 
                    <ConfirmCheckout {...routeProps} accessTokenProvider={this.getAccessToken}  />
                  }
                </div>
               
              )} />
            
            <Route path="/forgot-password" component={PasswordReset}></Route>
            <Route path="/reset-password" component={ResetPassword}></Route>
          </Switch>
          </Router>
          
          
          
        </Typography>
      </Container>
    </React.Fragment>
    )
  }
}


export default App;
