import React,{Component, ChangeEvent,useState, useEffect, CSSProperties} from 'react';

import {IAccessToken,IBotPermission,IUserCredentialsToken,ISubscriptionKey,IUserCredentials,ISubscriptionOption, PaypalClient, CreateOrderResponse, Order, ConfirmPaymentResponseBody, OrderHeaderDetail} from '../Types'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { string } from 'prop-types';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import {CheckCircleOutlineRounded} from '@material-ui/icons';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import moment from 'moment';

import RestHandler from './../RestHandler'

import Modal from '@material-ui/core/Modal';
import QueryStringHelper from '../QueryStringHelper';

interface IProps {
    accessTokenProvider:()=>string
}

const ConfirmCheckout:React.FC<IProps> = (props) => {

    const [orderConfirmationResponse,setOrderConfirmationResponse] = useState<ConfirmPaymentResponseBody | null>(null);
    const [orderConfirmationInProgress,setOrderConfirmationInProgress] = useState(false);
    const [order,setOrder] = useState<OrderHeaderDetail | null>(null);

    const paypalOrderId = QueryStringHelper.getQueryStringParam("token");
    const paypalPayerId = QueryStringHelper.getQueryStringParam("PayerID");
    
    const confirmPurchase = async (order:OrderHeaderDetail) => {

        setOrderConfirmationInProgress(true);

        const response = (await RestHandler.postData("v2/confirmPayment",{
            paypalPayerId:paypalPayerId,
            paypalOrderId:paypalOrderId
        },props.accessTokenProvider()) as ConfirmPaymentResponseBody);

        setOrderConfirmationInProgress(false);
        
        setOrderConfirmationResponse(response);

    }

    useEffect(()=> {

        let didCancel = false;

        async function fetchMyAPI() {

            const response = (await RestHandler.postData("v2/authorizePayment",
            {
                paypalPayerId:paypalPayerId,
                paypalOrderId:paypalOrderId
            },
            props.accessTokenProvider()) as OrderHeaderDetail);

            if (!didCancel) { // Ignore if we started fetching something else
                setOrder(response);
            }
        }  

        fetchMyAPI();
        return () => { didCancel = true; }; // Remember if we start fetching something else

    },[paypalOrderId])

    return (
    <div>
        {order === null && <div>Loading Order Confirmation <CircularProgress color="secondary" style={{margin:"10px",alignSelf:"center"}} /></div>}
        {order !== null && 
        <div>
            <Typography variant="h6" style={{margin:"5px"}}>
                <div>
                Please review your order details and confirm below. Order Total : $ {order.orderHeader.totalPrice}
                </div>
                </Typography>
        <Table>
            
            <TableHead>
                <TableRow>
                    <TableCell align="left">Order Id</TableCell>
                    <TableCell align="left">Product Description</TableCell>
                    <TableCell align="left">Key Duration</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {order.orderRows.map((row:Order)=>(
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.productName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.numberOfDaysAccess} Days
                                </TableCell>
                            </TableRow>
                    ))}
            </TableBody>
        </Table>
        {(order.orderHeader.orderStatus === "confirmed") && <div>This order has already been confirmed!</div>}
        
        {(order.orderHeader.orderStatus !== "confirmed") && 
            <div>
            {(orderConfirmationResponse === null && orderConfirmationInProgress === false) && 
                <div>
                <Button 
                variant="contained"
                onClick={()=>confirmPurchase(order)}>Confirm Purchase</Button>
                </div>
            }
            {(orderConfirmationInProgress === true) && 
                <div>Confirming your order. This can take a few minutes. Please wait... <CircularProgress color="secondary" style={{margin:"10px",alignSelf:"center"}} /></div>
            }
            {(orderConfirmationResponse !== null) && 
                <div>
                    {orderConfirmationResponse.paymentConfirmed && <div style={{display:"flex",flexDirection:"column"}}>
                        <div>Purchase Confirmed! <CheckCircleOutlineRounded></CheckCircleOutlineRounded></div>
                        <div><a href="/">Return to your subscriptions</a></div>
                        </div>}
                </div>
            }
            </div>
        }
        
        </div>
        
        }
    </div>)
}

export default ConfirmCheckout;