import React,{Component, ChangeEvent,useState, useEffect, CSSProperties} from 'react';

import {IAccessToken,IBotPermission,IUserCredentialsToken,ISubscriptionKey,IUserCredentials,ISubscriptionOption, PaypalClient, CreateOrderResponse} from '../Types'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { string } from 'prop-types';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import {CheckCircleOutlineRounded} from '@material-ui/icons';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import moment from 'moment';

import RestHandler from './../RestHandler'

import Modal from '@material-ui/core/Modal';
import PaypalButton from './PaypalButton';

interface IProps {
    open:boolean,
    onCancel: ()=>void;
    userCredentialsProvider: () => Promise<string>
}

const LoggedOut:React.FC<IProps> = (props) => {

    return (<div>
        <p>You have been logged out. <a href="/">Log in again</a></p>
        
    </div>)
}

export default LoggedOut;